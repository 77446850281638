<template>
  <v-card color="primary" class="mx-0 mx-sm-3">
    <v-container>
      <p class="text-left mb-3">User information from Google Reviews / Profile, etc</p>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-text-field required type="email" ref="searchField" :label="searchHolder" :persistent-hint="true" :disabled="searchDisabled" :clearable="true" v-model="toSearch" :placeholder="searchHolder" :rules="rules">
          <template v-slot:append-outer>
            <v-btn type="submit" :loading="loading">Submit</v-btn>
          </template>
        </v-text-field>
      </v-form>
      <div class="card_google">
        <div class="d-flex flex-wrap gap-10">
          <v-alert v-if="error" border="left" type="error">{{ error }}</v-alert>
          <v-card v-if="profile" width="500px">
            <v-card-title class="pb-0">Profile Data</v-card-title>
            <v-list color="transparent">
              <v-list-item>
                <v-list-item-title>Person ID</v-list-item-title>
                <v-list-item-content>
                  {{ profile.personId }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Name</v-list-item-title>
                <v-list-item-content>
                  {{ (profile.names.PROFILE && profile.names.PROFILE.fullname) || 'N/A' }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Profile Picture</v-list-item-title>
                <v-list-item-content>
                  <v-img height="100px" contain :src="profile.profilePhotos.PROFILE && profile.profilePhotos.PROFILE.url"></v-img>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Cover Picture</v-list-item-title>
                <v-list-item-content>
                  <v-img height="100px" contain :src="profile.coverPhotos.PROFILE && profile.coverPhotos.PROFILE.url"></v-img>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="profile.emails.PROFILE">
                <v-list-item-title>Profile Email</v-list-item-title>
                <v-list-item-content>{{ profile.emails.PROFILE.value }}</v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Last Updated</v-list-item-title>
                <v-list-item-content>{{ profile.sourceIds.PROFILE.lastUpdated || 'N/A' }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card width="300px" v-if="maps && stats.length">
            <v-card-title class="pb-0">Stats</v-card-title>
            <v-list color="transparent">
              <v-list-item v-for="(item, index) in stats" :key="index">
                <v-list-item-title>{{ item }}</v-list-item-title>
                <v-list-item-content>
                  {{ maps.stats[item] }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card width="100%" v-if="maps && maps.reviews.length">
            <v-card-title>Reviews</v-card-title>
            <v-data-table class="transparent" :headers="headersReviews" :items="maps.reviews">
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText" />
              </template>
              <template v-slot:item.location.name="{ item }">
                <a target="_blank" class="no_link" :href="getLink(item)">
                  {{ item.location.name }}
                </a>
              </template>
            </v-data-table>
          </v-card>
          <v-card width="100%" v-if="maps && maps.photos.length">
            <v-card-title>Photos</v-card-title>
            <v-data-table class="transparent" :headers="headersPhotos" :items="maps.photos">
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions" items-per-page-text="$vuetify.dataTable.itemsPerPageText" />
              </template>
              <template v-slot:item.location.name="{ item }">
                <a target="_blank" class="no_link" :href="getLink(item)">
                  {{ item.location.name }}
                </a>
              </template>
              <template v-slot:item.url="{ item }">
                <img height="150px" :src="item.url" />
              </template>
            </v-data-table>
          </v-card>
          <v-card v-if="raw" width="100%">
            <v-card-title>Raw Data</v-card-title>
            <v-card-text>
              <v-textarea :value="stringify(raw)"></v-textarea>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script type="ts">
import { getRules } from '@/services/constants';
import rService from '../services/RequestService/RequestServiceSetup';

export default {
  data() {
    return {
      raw: null,
      error: '',
      profile: null,
      toSearch: '',
      maps: null,
      valid: false,
      loading: false,
      typeSelected: 'email',
      searchDisabled: false,
      searchHolder: this.$vuetify.lang.t('$vuetify.Home.placeHolderLink.email'),
    };
  },
  beforeMount() {
    const email = this.$route.query.email;
    if (email) {
      this.toSearch = email;
      this.getData();
    }
  },
  methods: {
    getLink(item) {
      return `https://www.google.com/maps/search/${encodeURI(item.location.name)}/@${item.location.position.latitude},${item.location.position.longitude}`;
    },
    submit() {
      if (!this.valid) return;
      if (this.toSearch !== this.$route.query.email) {
        this.$router.push({
          query: {
            email: this.toSearch,
          },
        });
        this.getData();
      }
    },
    stringify(raw) {
      return JSON.stringify(raw);
    },
    async getData() {
      this.loading = true;
      this.error = '';
      const email = this.toSearch;
      const data = await rService.get('ghunt/data?email=' + encodeURIComponent(email));
      this.loading = false;
      if (!data.error) {
        const d = data.PROFILE_CONTAINER;
        this.profile = d.profile;
        this.maps = d.maps;
        this.raw = d;
      } else {
        this.error = data.error;
        this.profile = null;
        this.maps = null;
        this.raw = null;
      }
    },
    getRules() {
      return getRules(this.translate);
    },
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
  },
  computed: {
    headersPhotos() {
      const toReturn = [
        { text: 'Date', value: 'exact_date', width: 'auto' },
        { text: 'Address', value: 'location.address', width: 'auto' },
        { text: 'Location Name', value: 'location.name', width: 'auto' },
        { text: 'Tags', value: 'location.tags', width: 'auto' },
        { text: 'Type', value: 'location.types', width: 'auto' },
        { text: 'Photo', value: 'url', width: 'auto' },
      ];
      return toReturn;
    },
    headersReviews() {
      const toReturn = [
        { text: 'Rating', value: 'rating', width: 'auto' },
        { text: 'Date', value: 'approximative_date', width: 'auto' },
        { text: 'Address', value: 'location.address', width: 'auto' },
        { text: 'Location Name', value: 'location.name', width: 'auto' },
        { text: 'Tags', value: 'location.tags', width: 'auto' },
        { text: 'Type', value: 'location.types', width: 'auto' },
        { text: 'Comment', value: 'comment', width: 'auto' },
      ];
      return toReturn;
    },
    rules() {
      const { generalRules, ruleTypes } = this.getRules();
      const extra = ruleTypes[this.typeSelected];
      if (extra) {
        return [...generalRules, ...extra];
      } else {
        return [];
      }
    },
    stats() {
      return Object.keys(this.maps.stats);
    },
  },
};
</script>


<style lang="scss">
.card_google {
  .v-list-item {
    border-top: 1px solid #ffffff1a;
    .v-list-item__title {
      flex: 1;
      text-align: left;
    }
  }
}
</style>